import React from 'react'
import { Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Layout } from '../../flow-ui-layout/Layout/Layout'
import { Container } from 'theme-ui'

const PageCollection = ({ data: { posts, collectionInfo }, ...props }) => (
  <Layout {...props}>
    <Seo title={collectionInfo.name} description={collectionInfo.description} />
    <Divider />
    <Divider />
    <Divider />
    <Divider />
    <Divider />
    <Divider />
    <Divider />

    <Stack effectProps={{ effect: 'fadeInDown' }}>
      <Container sx={{ textAlign: 'center' }} variant={'full'}>
        <PageTitle
          header={collectionInfo.name}
          subheader={collectionInfo.title}
          running={collectionInfo.description}
        />
      </Container>
    </Stack>
    <Divider />

    <Stack>
      <Main>
        {posts.nodes && (
          <CardList
            nodes={posts.nodes.map(n => {
              const { author, date, timeToRead, excerpt, ...node } = n
              return node
            })}
            variant={['horizontal-md', 'vertical']}
            columns={[1, 2, 3, 3]}
            omitCategory={
              props.pageContext &&
              props.pageContext.collectionType === 'category'
            }
          />
        )}
      </Main>
    </Stack>
    <Divider />
    <PreFooter>
      <Pagination {...posts.pageInfo} {...collectionInfo} />
    </PreFooter>
  </Layout>
)

export default PageCollection
